@import "../../globalStyles/_variables";

html,
body {
    background-color: black !important;
    overflow-x: hidden;
}

.header__container {
    min-height: 100vh;
    color: $clr-white;
    background-color: $bg-clr-black;

    @include minMediaQuery(xlarge) {
        min-height: 100vh;
    }
    .header__wrapper {
        height: fit-content;
        @include minMediaQuery(xlarge) {
            display: grid;
        }
    }
    &-info {
        min-height: 82.5vh;
    }
}

.header__container-info {
    padding-inline: $padding-m;
    min-height: 100vh;
    width: 100vw;
    position: relative;
    overflow: hidden;
    display: grid;

    .header__button {
        display: flex;
        align-items: center;
        justify-content: center;
        button {
            @include buttonStyler($bg-clr-blue-6, $clr-white);
        }
    }
}
.header__title {
    h3 span {
        font-size: $fs-xxlarge;
        display: block;
        text-align: center;
        text-transform: uppercase;
    }

    h3 span:nth-child(2) {
        color: $clr-blue-3;
    }

    @include minMediaQuery(xlarge) {
        h3 span:nth-child(1) {
            text-align: left;
        }
        h3 span:nth-child(2) {
            text-align: left;
            margin-left: 55px;
        }
        h3 span:nth-child(3) {
            text-align: left;
            margin-left: 120px;
        }
    }
    @include minMediaQuery(large) {
        margin-top: 50px;
    }
}
.header__subinfo {
    text-align: center;
    font-size: $fs-subinfo-medium;
    color: $clr-light-blue-3;
    padding-block: $padding-m $padding-xsm;
    max-width: $maxWidth-2;
    margin-inline: auto;

    @include minMediaQuery(xlarge) {
        text-align: left;
    }
}

.header__container-image {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* about styles */
.about-con {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}
.abt-img {
    flex-basis: 50%;
    max-width: 50%;
    align-items: flex-start;

    img {
        width: 100%;
        height: auto;
    }
}
.about-txt .intro-con {
    display: flex;
    flex-direction: column;
    align-items: flex-start; /* Adjusted alignment to start */
}
.abt-txt,
.company-intro {
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
    justify-content: center;
    align-items: flex-start;
    padding: 0 20px;
    margin-top: 0;
    margin-bottom: 0;

    .head-txt {
        color: $clr-white;
        font-weight: bolder;
        text-transform: uppercase;
        -webkit-text-stroke: 1px #000000;
        align-items: center;

        h1 {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
    }
}

@media (max-width: 768px) {
    .about-con {
        flex-direction: column;
    }

    .abt-img,
    .abt-txt {
        flex-basis: 80%;
        max-width: 80%;
    }
}
