// mixins at bottom

// screen sizes ,small,medium,large
$medium-size: 39.375em; //630px
// screen size map
$screenSize: (
    "medium": 630px,
    "large": 900px,
    "xlarge": 1400px,
);

//maxwidths
$maxWidth-small: 35em;
$maxWidth-1: 36.75em;
$maxWidth-2: 40.125em;
$maxWidth-3: 43.625em;
$maxWidth-4: 56.3125em;
$maxWidth-5: 79.1875em;
$maxWidth-largest: 100em;

// font sizes
$fs-base-medium: 1.2rem;
$fs-base-medium-2: 1.5rem;
$fs-base-large: 2rem;
$fs-base-sm: 0.875rem;
$fs-base: 1rem;
$fs-xlarge: clamp(1.85rem, 1.4405rem + 2.0476vw, 4rem);
$fs-xxlarge: clamp(1.85rem, 1.0095rem + 4.2025vw, 6rem);
$fs-subinfo-medium: clamp(0.875rem, 0.7083rem + 0.8333vw, 1.75rem);
$fs-subinfo-small: clamp(0.875rem, 0.475rem + 2vw, 1rem);
$fs-subheading-medium: clamp(1.85rem, 1.7262rem + 0.619vw, 2.5rem);
$fs-subheading-medium-small: clamp(0.85rem, 0.3088rem + 2.7059vw, 2rem);
$fs-subheading-small: clamp(0.875rem, -0.485rem + 6.8vw, 1.3rem);
$fs-subinfo: clamp(0.875rem, 0.756rem + 0.5952vw, 1.5rem);

// font weights
$fw-medium: 500;
$fw-bold: 600;
$fw-bolder: 700;

// icon sizes
$icon-size: 90px;

// background colors
$bg-clr-black: #010101;
$bg-clr-blue-1: #020015;
$bg-clr-blue-2: #07032e;
$bg-clr-blue-3: #05072a;
$bg-clr-blue-3-5: #08061e;
$bg-clr-blue-4: #2e2d58;
$bg-clr-blue-5: #9b9ef8;
$bg-clr-blue-6: #1300f1;
$bg-clr-light-grey: #3c3737;
$bg-clr-blue-grey: #111326;

//font colors
$clr-white: #ffffff;
$clr-blue-1: #111fa1;
$clr-blue-2: #1300f1;
$clr-blue-3: #0000ff;
$clr-light-blue-1: #8286f3;
$clr-light-blue-2: #938aef;
$clr-light-blue-3: #babdff;
$clr-gold: #ffc804;

//colors
$translucent-white: rgba(255, 255, 255, 0.3);
// gradients
$gradient-1: linear-gradient(180deg, #020015 0%, #3c3969 84.55%);
$gradient-2: linear-gradient(
    180deg,
    #000224 0%,
    rgba(8, 11, 68, 0.81) 99.99%,
    rgba(65, 70, 157, 0.76) 100%
);

// border radius
$border-radius-vsm: 5px;
$border-radius-sm: 8px;
$border-radius-m: 10px;

// spacing
$padding-xsm: 0.5em;
$padding-sm: 1em;
$padding-m: 1.5em;
$padding-ml: 2em;
$padding-l: 3em;

// utility classes
.overline__title {
    position: relative;
}
.overline__title::before {
    content: "";
    position: absolute;
    width: 15%;
    max-width: 65px;
    height: 5px;
    top: -12px;
    background-color: white;
}
.overline__center {
    width: 15%;
    max-width: 65px;
    height: 5px;
    background-color: White;
    margin-inline: auto;
    margin-bottom: 10px;
}
// button mixin
@mixin buttonStyler($bg-clr, $txt-clr) {
    padding: 0.75em $padding-ml;
    border: none;
    border-radius: 0.5em;
    background-color: $bg-clr;
    color: $txt-clr;
    cursor: pointer;
}
// media query mixin
@mixin maxMediaQuery($size) {
    @media (max-width: map-get($screenSize,$size)) {
        @content;
    }
}
@mixin minMediaQuery($size) {
    @media (min-width: map-get($screenSize,$size)) {
        @content;
    }
}
