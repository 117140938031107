/* .projects__container-title {
    margin-top: -250px;
}  */
.outline-projects {
    /* font-size: 5rem !important; */
    margin-top: 0;
    font-size: clamp(5rem, -1.4554rem + 21.4286vw, 4rem);
    font-weight: 900 !important;
    letter-spacing: 2px !important;
    color: #e5e4e2 !important;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
        1px 1px 0 #000;
}

.no-outline-projects {
    color: #ffd700 !important;
    -webkit-text-stroke: 2px #000000 !important;
    text-stroke: 2px #000000 !important;
}

.gold-shade {
    color: #ffd700 !important;
}

.mobile-line-break {
    display: none !important;
}

.text-underline {
    text-decoration: wavy;
    color: #fff;
}

.bgAnimationContainer {
    margin-top: -300px !important;
}

@media only screen and (max-width: 480px) {
    .mobile-line-break {
        display: inline-block !important;
    }

    .bgAnimationContainer {
        display: none;
    }

}
