:root {
    --primary-color: rgba(0, 0, 255, 0.75);
    --secondary-color: rgba(255, 215, 0, 1);
    --tertiary-color: rgba(0, 0, 0, 0.9)
}

.footer .button {
    width: 55px;
    height: 55px;
    border: rgba(255, 215, 0, 1) 12px solid;
    border-radius: 35px;
    margin: 0 auto;
    position: relative;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;
    cursor: pointer;
}

.footer .button:hover {
    width: 35px;
    height: 35px;
    border: rgba(58, 58, 58, 0) 12px solid;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;
    position: relative;
    cursor: pointer;
}

.footer {
    font-family: 'Montserrat', sans-serif !important;
    color: #ffffff !important;
    font-weight: 600 !important;
    bottom: 0;
    left: 0;
    position: fixed;
    width: 100%;
    height: 3.5rem;
    overflow: hidden;
    margin: 0 auto;
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;
    z-index: 999;
}

.footer:hover {
    -webkit-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -o-transition: all 1s ease;
    -ms-transition: all 1s ease;
    transition: all 1s ease;
    height: 16em;
}

.footer .footer-container {
    margin-top: 5px;
    margin-right: 0;
    width: 100%;
    height: 100%;
    position: relative;
    top: 0;
    left: 0;
    background: var(--tertiary-color);
}

.footer .footer-inner-container {
    position: relative;
    top: -45px;
    right: 190px;
    width: 150px;
    height: auto;
    margin: 0 auto;
}

.footer-center {
    width: 500px;
    float: left;
    text-align: center;
}

.footer .logo {
    height: 100px;
    width: auto;
}

.footer-line-break {
    display: none;
}

.text-gold {
    color: var(--secondary-color);
}

.close-contact-section-sm {
    cursor: pointer;
    display: none;
}

.close-contact-section-lg {
    cursor: pointer;
    display: none
}

.copyright-text {
    font-size: 13px;
}

/* Animations */
@keyframes animate-pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(255, 215, 0, 0.7), 0 0 0 0 rgba(255, 215, 0, 0.7);
    }
    40% {
        box-shadow: 0 0 0 50px rgba(255, 215, 0, 0.0), 0 0 0 0 rgba(255, 215, 0, 0.7);
    }
    80% {
        box-shadow: 0 0 0 50px rgba(255, 215, 0, 0.0), 0 0 0 30px rgba(255, 215, 0, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(255, 215, 0, 0.0), 0 0 0 30px rgba(255, 215, 0, 0);
    }
}

/* Media Queries */
@media only screen and (max-width: 480px) {
    .footer:hover {
        -webkit-transition: all 1s ease;
        -moz-transition: all 1s ease;
        -o-transition: all 1s ease;
        -ms-transition: all 1s ease;
        transition: all 1s ease;
        height: 26.5em;
    }

    .middle-column {
        display: none !important;
    }

    .right-column-section {
        margin-top: -1rem !important;
        text-align: left !important;
    }

    .vertical-bar-footer {
        display: none !important;
    }

    .footer-line-break {
        display: inline-block !important;
    }

    .footer .logo {
        height: 70px;
        width: auto;
    }

    .location-text {
        font-size: 14px;
    }

    .copyright-text {
        font-size: 13px;
    }

    .close-contact-section-sm {
        display: inline-block;
    }

    .close-contact-section-lg {
        display: none;
    }
}
