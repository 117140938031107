.go-down-btn {
    position: relative;
    z-index: 1;
    display: inline-block;
}
.rotating-text {
    padding: 1em;
    transition: all 1s ease-in-out;
}

.hide-rotating-text {
    opacity: 0;
}
.show-rotating-text {
    opacity: 1;
}

.go-down-btn svg {
    position: relative;
    z-index: -1;
    display: block;
    color: #ffd700;
}

.go-down-btn text {
    font-size: 11px;
    transform-origin: center;
}

@-webkit-keyframes rotate-btn {
    to {
        transform: rotate(360deg);
    }
}

@keyframes rotate-btn {
    to {
        transform: rotate(360deg);
    }
}

.go-down-btn text {
    -webkit-animation: rotate-btn 10s linear infinite;
    animation: rotate-btn 10s linear infinite;
}

.gold-text {
    fill: #ffd700 !important;
}

.white-text {
    fill: #ffffff !important;
}
