@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600&display=swap");

/* Box sizing rules */
*,
*::before,
*::after {
    box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
    margin: 0;
}

p {
    line-height: 1.7;
}

ul li {
    list-style-type: none;
}
/* Set core root defaults */
html:focus-within {
    scroll-behavior: smooth;
}

/* Set core body defaults */
body {
    min-height: 90vh;
    text-rendering: optimizeSpeed;
    font-family: 'Montserrat', sans-serif !important;
}
a {
    text-decoration: none;
}

/* Make images easier to work with */
img,
picture {
    max-width: 100%;
    // display: block;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
    font: inherit;
}
