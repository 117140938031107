@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@300&display=swap');

:root {
    --primary-color: rgba(135, 220, 244, 0.75);
    --overlay-color: rgba(3, 56, 99, 0.98);
    --secondary-color: rgba(255, 215, 0, 1);
    --menu-speed: 0.75s;
}

.menu-wrap {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    margin-left: 80px;
    margin-top: 50px;
    z-index: 1000;
}

.menu-wrap .toggler {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2000;
    cursor: pointer;
    width: 100px;
    height: 100px;
    opacity: 0;
}

.menu-wrap .hamburger {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 60px;
    height: 60px;
    padding: 0.65rem;
    /* background: var(--primary-color); */
    display: flex;
    align-items: center;
    justify-content: center;
}


.menu-wrap .hamburger:after {
    content: 'MENU';
    color: #ffffff;
    padding-left: 7px;
}
/* Top and bottom hamburger lines */
.menu-wrap .hamburger > div {
    position: relative;
    flex: none;
    width: 100%;
    height: 1px;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.6s ease;
}

.menu-wrap .hamburger > div:before,
.menu-wrap .hamburger > div:after {
    content: '';
    position: absolute;
    z-index: 1;
    top: -10px;
    width: 100%;
    height: 1px;
    background: inherit;
}

/* Moves line down */
.menu-wrap .hamburger > div:after {
    top: 10px;
}

/* Toggler animate */
.menu-wrap .toggler:checked + .hamburger > div {
    transform: rotate(135deg);
}

/* Turn lines into X */
.menu-wrap .toggler:checked + .hamburger > div:before,
.menu-wrap .toggler:checked + .hamburger > div:after {
    top: 0;
    transform: rotate(90deg);
}

/* ROtate on hover when checked */
.menu-wrap .toggler:checked:hover + .hamburger > div {
    transform: rotate(225deg);
}

/* Show menu */
.menu-wrap .toggler:checked ~ .menu {
    visibility: visible;
}

.menu-wrap .toggler:checked ~ .menu > div {
    transform: scale(1);
    transition-duration: var(--menu-speed);
}

.menu-wrap .toggler:checked ~ .menu > div > div {
    opacity: 1;
    transition: opacity 0.6s ease 0.6s;
}

.menu-wrap .menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    visibility: hidden;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.menu-wrap .menu > div {
    background: var(--overlay-color);
    border-radius: 50%;
    width: 200vw;
    height: 200vh;
    display: flex;
    flex: none;
    align-items: center;
    justify-content: center;
    transform: scale(0);
    transition: all 0.6s ease;
}

.menu-wrap .menu > div > div {
    text-align: center;
    max-width: 90vw;
    max-height: 100vh;
    opacity: 0;
    transition: opacity 0.6s ease;
}

.menu-wrap .menu > div > div > ul > li {
    list-style: none;
    color: #fff;
    font-size: 3rem;
    padding: 1rem;
}

.menu-wrap .menu > div > div > ul > li > a {
    color: inherit;
    text-decoration: none;
    transition: color 0.4s ease;
}

.menu-wrap .logo-container {
    position: relative;
    height: 150px;
    width: auto;
    margin-right: 10rem;
    margin-top: -1rem;
}

.menu-wrap .nav-logo {
    position: absolute;
    top: 0;
    right: 0;
    height: 100px;
    width: 100px;
}

.companyName {
    position: absolute;
    font-family: 'Josefin Sans', sans-serif;
    font-size: 14px;
    right: 0;
    margin-top: 80px;
}

/* Media Queries */
@media only screen and (max-width: 480px) {
    .menu-wrap {
        margin-left: 50px;
    }

    .menu-wrap .logo-container {
        margin-right: 5rem;
        margin-top: -0.4rem;
    }

    .menu-wrap .nav-logo {
        height: 70px;
        width: 70px;
    }

    .menu-wrap .menu > div > div > ul > li {
        list-style: none;
        color: #fff;
        font-size: 2.5rem;
        margin-left: -1.5rem;
    }

    .companyName {
        margin-top: 60px;
        font-size: 10px;
    }
}