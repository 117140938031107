.scroll-rotator-about-us {
    display: none !important;
    width: 50%;
    /* margin: auto; */
    /* margin-top: -11.2rem; */
    margin-inline: auto;
    align-self: flex-end;
    margin-bottom: -27px;
    text-align: center;
    z-index: 400;
}

.scroll-rotator-about-us .rotating-text {
    padding: 0;
}
.outline-about-us {
    font-weight: 900;
    letter-spacing: 2px;
    color: #e5e4e2;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
        1px 1px 0 #000;
}

.no-outline-about-us {
    color: #ffd700;
    -webkit-text-stroke: 2px #000000;
    text-stroke: 2px #000000;
}
/* 
@media only screen and (max-width: 480px) {
    .aboutUs__container-info-wrapper {
        margin-top: 7rem !important;
    }
    .scroll-rotator-about-us {
        width: 50% !important;
        margin: auto;
        margin-right: 6.1rem;
        margin-top: 6.2rem;
    }
} */
