@import "../../globalStyles/_variables";

.footer__container {
    background-color: $bg-clr-blue-3;
    min-height: 100vh;

    @include minMediaQuery(large) {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    &-wrapper {
        @include maxMediaQuery(large) {
            margin-top: 30px;
        }
        @include minMediaQuery(large) {
            padding: $padding-l;
            display: flex;
            gap: 2em;
            justify-content: center;
        }
    }
}

.footer__info {
    text-align: center;
    padding-block: $padding-m 0;
    padding-inline: $padding-sm;
    color: $clr-white;

    div {
        margin-bottom: $padding-sm;
    }

    div p {
        padding-top: $padding-xsm;
        font-size: clamp(0.875rem, 0.806rem + 0.3448vw, 1rem);
    }

    &-address,
    &-title {
        p {
            color: $clr-light-blue-3;
            max-width: $maxWidth-small;
            margin-inline: auto;
        }
    }

    &-email,
    &-phone {
        p a {
            color: $clr-white;
        }
    }
    &-socials {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        gap: $padding-xsm;
        padding-block: 0 $padding-sm;

        p {
            color: $clr-white;
        }
    }
}

.footer__form {
    border: 1px solid green;
    width: 85%;
    max-width: $maxWidth-1;
    margin: auto;
    padding-inline: $padding-sm;
    padding-block: $padding-sm;
    text-align: center;
    box-shadow: 1px 1px 12px 3px rgba(130, 134, 243, 0.34);
    border-radius: $border-radius-m;

    @include minMediaQuery(large) {
        margin: 0;
    }
    ::placeholder {
        color: $clr-light-blue-3;
    }

    h2 {
        color: $clr-white;
        margin-bottom: $padding-sm;
    }

    form input:focus {
        outline: none;
        border-bottom: 2px solid blue;
    }

    form input {
        color: $clr-white;
        width: 100%;
        border: none;
        margin-bottom: $padding-sm;
        padding-block: $padding-sm 0;
        background-color: transparent;
        border-bottom: 1px solid $clr-white;
    }

    form textarea {
        width: 100%;
        color: $clr-white;
        background-color: transparent;
        border: 1px solid $clr-white;
        margin-block: $padding-sm $padding-ml;

        ::placeholder {
            font-size: 20px;
        }
    }
    button {
        @include buttonStyler($bg-clr-blue-6, $clr-white);
    }
}

.footer__date {
    text-align: center;
    color: $clr-white;
    padding: $padding-m;
}
