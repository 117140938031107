@import "../../globalStyles/_variables";

.digitize__container {
    padding: $padding-ml 5px;
    background-color: $bg-clr-blue-2;
    text-align: center;
    border-radius: 20px;
    margin-block: 20px 0;

    @include minMediaQuery(large) {
        text-align: left;
        display: grid;
        justify-content: center;
        grid-template-columns: 2fr 3fr;
    }

    &-info {
        display: grid;
        justify-items: center;
        align-items: center;
        padding-inline: $padding-sm;
    }

    &-title h2 {
        font-size: $fs-subheading-medium;
        color: $clr-gold;

        & > span {
            color: $clr-white;
        }
    }

    &-title p {
        max-width: $maxWidth-2;
        margin: auto;
        font-size: $fs-subinfo;
        color: $clr-light-blue-2;
        padding-block: $padding-sm;
    }
}

.digitize__container-gallery {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 14.5em;

    gap: 0.5em;

    img {
        height: 100%;
        width: 100%;
        border: 1px solid $clr-light-blue-1;
        border-radius: $border-radius-m;
        object-fit: cover;
        object-position: center;
    }

    &-image3 {
        grid-column: 1 / span 2;
        @include minMediaQuery(large) {
            grid-column: 4 / span 3;
            grid-row: 3 / span 2;
        }
    }

    &-image2 {
        @include minMediaQuery(large) {
            grid-column: 2 / span 3;
            grid-row: 2 / span 2;
        }
    }

    &-image1 {
        @include minMediaQuery(large) {
            grid-column: 1 / span 3;
            grid-row: 1 / span 2;
        }
    }

    @include minMediaQuery(medium) {
        max-width: $maxWidth-3;
        margin: auto;
        grid-auto-rows: 21.5em;
    }

    @include minMediaQuery(large) {
        grid-template-columns: repeat(6, 1fr);
        grid-auto-rows: auto;
        max-width: $maxWidth-4;
    }
}
