@import "../../globalStyles/_variables";

.services__container {
    padding: $padding-xsm 8px $padding-ml 8px;
    background-color: $bg-clr-blue-1;
    text-align: center;

    &-title {
        margin-block: 20px 0;
        font-size: $fs-subheading-medium;
        padding-block: $padding-m;
        color: $clr-white;
        @include minMediaQuery(large) {
            margin-block: 35px 0;
        }
    }
}

.services__cards {
    display: grid;
    justify-content: center;
    align-items: start;
    gap: $padding-m;
    grid-template-columns: repeat(auto-fit, minmax(12.5em, 18.75em));
    grid-auto-rows: min-content;
}
.services__card {
    background: $bg-clr-blue-grey;
    box-shadow: 6px 7px 12px rgba(78, 63, 215, 0.42);
    min-height: 15.75em;
    border-radius: $border-radius-vsm;
    padding-block: $padding-sm;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;

    @include minMediaQuery(medium) {
        min-height: 18.75em;
    }

    &-info {
        // min-height: 150px;
        height: 0;
        overflow: hidden;
        display: grid;
        align-items: center;
    }

    .show-info {
        height: auto;
    }
    &-info-subtext {
        font-size: $fs-subinfo-small;
        color: $clr-white;
        padding-inline: $padding-sm;
        padding-block: $padding-xsm 0;
    }
    &-icon {
        display: flex;
        min-height: 15.6875em;
        flex-direction: column;
        gap: 3em;
        color: $clr-white;
        align-items: center;
        justify-content: center;
        padding-block: 1em 0;

        &-title {
            color: $clr-gold;
            padding-inline: $padding-sm;
            font-size: $fs-subheading-small;

            text-transform: capitalize;
        }

        &-wrapper {
            display: grid;
            place-items: center;
            border: 2px solid $clr-white;
            // padding-inline: $padding-sm;
            border-radius: 100%;
            width: 80px;
            aspect-ratio: 1/1;

            p {
                font-size: 2em;
            }
        }
    }
    .indicators {
        // margin-top: 15px;
        font-size: 2rem;
        margin: 15px auto;
        width: 30px;
        height: 30px;
        cursor: pointer;
    }
}
