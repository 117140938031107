@import "../../globalStyles/_variables";

.projects__container {
    min-height: 100vh;
    width: 100%;
    text-align: center;
    background: #000;
    overflow: hidden;

    &-title {
        min-height: 100vh;
        display: grid;

        // flex-direction: column;
        // align-items: center;
        // justify-content: center;
    }
    &-title h2 {
        // font-size: $fs-xlarge;
        align-self: flex-end;

        color: $clr-white;
        // padding: $padding-sm;

        @keyframes animate {
            from {
                opacity: 0;
                transform: translateY(50px);
                filter: blur(5px);
            }
            to {
                opacity: 1;
                transform: translateY(0);
                filter: blur(0);
            }
        }

        & > span {
            color: $clr-gold;
            opacity: 0;
            display: inline-block;
            animation: animate 1s linear forwards;
            -webkit-animation: animate 1s linear forwards;
        }
    }
}

.project__info {
    position: relative;
    padding: 0 0 $padding-sm $padding-sm;

    &-navbuttons-icon {
        font-size: $fs-base;
        position: absolute;
        top: 95%;
        z-index: 2;
        transform: translateY(-95%);
        background: none;
        background-color: rgba(0, 0, 0, 0.5);
        border: none;
        color: rgba(255, 255, 255, 0.6);
        border-radius: 0.25em;
        padding: 10px;
    }
    .prev {
        margin-left: 0.5em;
    }
    .next {
        right: 0.5em;
    }
}
.projects__wrapper {
    display: grid;
    gap: 50px;
    padding-block: $padding-sm;
    scroll-snap-type: inline mandatory;
    //overflow: auto;
    scroll-behavior: smooth;
}
.project__wrapper {
    min-height: 50vh;
    min-width: 300px;
    width: 60%;
    margin: auto;
    gap: $padding-xsm;

    @include minMediaQuery(xlarge) {
        display: grid;
        place-content: center;
        grid-template-columns: repeat(2, 1fr);
    }

    .project__title {
        padding-block: 1.5em 0;

        // font-size: clamp(1rem, 0.8095rem + 0.9524vw, 2rem);
        // padding-inline: $padding-xsm;
        // font-weight: bold;
        p,
        h2,
        h3 {
            text-align: left;
            font-weight: bold;
        }
        h2,
        h3 {
            color: $clr-white;
        }
        h2 {
            font-size: 2rem;
        }

        @include minMediaQuery(large) {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        @include minMediaQuery(xlarge) {
            flex-direction: column;
            justify-content: center;
            align-items: start;
            margin-left: 1em;
        }
    }

    .project__image {
        border-radius: 10px;
        position: relative;
        filter: drop-shadow(1px 2px 8px rgba(145, 143, 143, 0.72));
        overflow: hidden;

        --width: 100%;

        &::after {
            content: "";
            position: absolute;
            width: var(--width);
            height: 100%;
            top: 0;
            right: 0;
            background: hsl(0 0% 100% / 0.3);
        }

        img {
            min-height: 390px;
            height: 100%;
            margin: auto;
            border-radius: 10px;
            object-fit: cover;
        }
    }
}
