.app-garden-content-container {
    margin-top: 50px !important;
    margin-bottom: 0px !important;
}

#search {
    padding-left: 40px !important;
}

::-webkit-input-placeholder {
    padding-left: 0px !important;
}

:-moz-placeholder {
    padding-left: 0px !important;
}

::-moz-placeholder {
    padding-left: 0px !important;
}

:-ms-input-placeholder {
    padding-left: 0px !important;
}