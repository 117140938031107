:root {
    --color-1: #fed330;
    --color-2: #e63841;
    --color-3: #5ed6fd;
    --color-4: #45c33b;
    --color-5: #1172c0;
}

.app-garden-container {
    margin: 0 auto;
    margin-bottom: 50px;
    width: 100%;
    padding: 0 1rem;
}

.app-garden-header-title {
    text-align: center;
    font-size: clamp(3.5rem, -1.4554rem + 21.4286vw, 4rem);
    font-weight: 900;
}

.text-center {
    text-align: center;
}

.app-garden-text-section {
    width: 550px;
    margin: 0 auto;
}

.app-garden-preview-section {
    width: 90%;
    margin: 2rem auto;
    display: grid;
    gap: 0.75em;
    grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
}

.app-garden-card {
    width: 100%;
    cursor: default;
    padding: 1.25rem;
    border-radius: 0.25rem;
    background-color: transparent;
    transition: transform 0.3s ease-in-out;
}

.app-garden-card:hover {
    transform: translateY(-0.5rem);
}

/* .app-garden-card {
    width: 100%;
    
    overflow: hidden;
    position: relative;
    border-radius: 0.25rem;
} */

.app-garden-card-title {
    margin: 0.75rem 0;
}

/* Helpers */

.striped-border {
    padding: 20px;
    border: 1.5px solid;
    border-image: -moz-linear-gradient(left, #fed330 0%, #fed330 20%, #e63841 20%, #e63841 20%, #e63841 40%, #5ed6fd 40%, #5ed6fd 40%, #e63841 40%, #5ed6fd 40%, #5ed6fd 40%, #5ed6fd 60%, #45c33b 60%, #45c33b 80%, #1172c0 80%, #1172c0 80%, #1172c0 100%); /* FF3.6+ */
    border-image: -webkit-gradient(linear, left top, right top, color-stop(0%,#fed330), color-stop(20%,#fed330), color-stop(20%,#e63841), color-stop(20%,#e63841), color-stop(40%,#e63841), color-stop(40%,#5ed6fd), color-stop(40%,#5ed6fd), color-stop(40%,#e63841), color-stop(40%,#5ed6fd), color-stop(40%,#5ed6fd), color-stop(60%,#5ed6fd), color-stop(60%,#45c33b), color-stop(80%,#45c33b), color-stop(80%,#1172c0), color-stop(80%,#1172c0), color-stop(100%,#1172c0)) 1 stretch repeat; /* Chrome,Safari4+ */
    border-image: -webkit-linear-gradient(left, #fed330 0%,#fed330 20%,#e63841 20%,#e63841 20%,#e63841 40%,#5ed6fd 40%,#5ed6fd 40%,#e63841 40%,#5ed6fd 40%,#5ed6fd 40%,#5ed6fd 60%,#45c33b 60%,#45c33b 80%,#1172c0 80%,#1172c0 80%,#1172c0 100%) 1 stretch repeat; /* Chrome10+,Safari5.1+ */
    border-image: -o-linear-gradient(left, #fed330 0%,#fed330 20%,#e63841 20%,#e63841 20%,#e63841 40%,#5ed6fd 40%,#5ed6fd 40%,#e63841 40%,#5ed6fd 40%,#5ed6fd 40%,#5ed6fd 60%,#45c33b 60%,#45c33b 80%,#1172c0 80%,#1172c0 80%,#1172c0 100%) 1 stretch repeat; /* Opera 11.10+ */
    border-image: -ms-linear-gradient(left, #fed330 0%,#fed330 20%,#e63841 20%,#e63841 20%,#e63841 40%,#5ed6fd 40%,#5ed6fd 40%,#e63841 40%,#5ed6fd 40%,#5ed6fd 40%,#5ed6fd 60%,#45c33b 60%,#45c33b 80%,#1172c0 80%,#1172c0 80%,#1172c0 100%) 1 stretch repeat; /* IE10+ */
    border-image: linear-gradient(to right, #fed330 0%,#fed330 20%,#e63841 20%,#e63841 20%,#e63841 40%,#5ed6fd 40%,#5ed6fd 40%,#e63841 40%,#5ed6fd 40%,#5ed6fd 40%,#5ed6fd 60%,#45c33b 60%,#45c33b 80%,#1172c0 80%,#1172c0 80%,#1172c0 100%) 1 stretch repeat; /* W3C */
}

.multi-color-text {
    background: linear-gradient(219deg, var(--color-1) 19%, transparent 19%, transparent 20%, var(--color-2) 20%, var(--color-2) 39%, transparent 39%, transparent 40%, var(--color-3) 40%, var(--color-3) 59%, transparent 60%, var(--color-4) 60%, var(--color-4) 79%, transparent 79%, transparent 80%, var(--color-5) 80%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
}

.text-grey {
    color: rgba(153, 157, 160, 1);
}

.mt-1{margin-top:.25rem!important}
.mt-2{margin-top:.5rem!important}
.mt-3{margin-top:1rem!important}
.mt-4{margin-top:1.5rem!important}
.mt-5{margin-top:3rem!important}

.p-0{padding:0!important}
.p-1{padding:.25rem!important}
.p-2{padding:.5rem!important}
.p-3{padding:1rem!important}
.p-4{padding:1.5rem!important}
.p-5{padding:3rem!important}

@media screen and (max-width: 450px) {
    .app-garden-text-section {
        width: 320px;
    }

    .app-garden-preview-section {
        width: 320px;
    }
}