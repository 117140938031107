@import "../../globalStyles/_variables";

.aboutUs__container {
    padding-block: 0 $padding-ml;
    text-align: center;
    background: #000;
    color: $clr-white;

    .main-wrapper {
        display: grid;
        // justify-items: center;
        // align-items: flex-end;
        min-height: 100vh;
    }
    &-info-wrapper {
        max-width: $maxWidth-largest;
        margin-block: 40px 0;
        align-self: center;
        display: grid;
        gap: 2em;
        padding-block: 3em 0;
        margin-inline: auto;
        padding-inline: $padding-sm;

        @media (max-height: 43em) {
            padding-block: 0;
            margin-block: 0;
        }

        @include minMediaQuery(large) {
            margin-top: 60px;
            grid-template-columns: 1fr 1fr 1fr 2fr 1fr 1fr;
        }
    }
    &-info {
        display: grid;
        max-width: $maxWidth-2;
        margin: auto;
        box-shadow: 7px 7px 6px rgba(60, 57, 105, 0.5);
        border-radius: $border-radius-sm;
        overflow: hidden;

        @include minMediaQuery(large) {
            max-width: $maxWidth-5;
            max-height: 700px;
            margin: 0;
            grid-column: 1 / span 4;
            grid-row: 1 / span 8;
        }

        &-image {
            position: relative;
        }
        &-image img {
            // filter: brightness(0.45);
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
        &-image::after {
            content: " ";
            width: 100%;
            background: rgb(0, 0, 0);
            background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.8287037037037037) 50%,
                rgba(11, 11, 11, 0.16666666666666663) 80%
            );
            height: 100%;
            bottom: 0;
            left: 0;
            position: absolute;
        }
        &-title {
            z-index: 1;
            width: 60%;
            max-width: 25em;
            padding-block: 30px;
            margin: auto;
            border: 0.7px solid $translucent-white;

            @include minMediaQuery(large) {
                width: 50%;
                margin-inline: 35px;
            }

            @include minMediaQuery(xlarge) {
                margin-inline: 100px;
            }
        }

        &-title h2 {
            font-size: $fs-xxlarge;
            color: $clr-gold;
            padding-inline: $padding-xsm;
        }
    }

    &-info > * {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
    }

    &-team-title {
        padding-block: $padding-sm;
        font-size: $fs-subheading-medium-small;
        & > h2 span {
            color: $clr-gold;
        }
    }
}

.aboutUs__container-subinfo {
    max-width: $maxWidth-2;
    height: max-content;
    margin-inline: auto;
    // margin-block: $padding-sm;
    padding-block: $padding-xsm 0;
    padding-inline: 8px;
    // border: 1px solid $translucent-white;
    color: $clr-white;

    @include minMediaQuery(large) {
        max-width: none;
        margin: 0;
        padding-inline: $padding-xsm;
        padding-block: $padding-m $padding-xsm;
        background: rgba(0, 0, 0, 0.25);
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
        text-align: left;
        height: fit-content;
        grid-column: 4 / span 3;
        grid-row: 3 / span 5;
        z-index: 1;
    }

    &-title {
        font-size: $fs-subheading-medium-small;
        & > h3 span {
            color: $clr-gold;
        }
    }

    p {
        color: $clr-light-blue-3;
        padding-block: $padding-xsm;
        font-size: $fs-subinfo;
        margin-bottom: 0;
    }
}

.person__card-wrapper {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(auto-fit, minmax(9.375em, 15.4375em));
    gap: $padding-sm;

    .person__card-image img {
        filter: drop-shadow(7px 4px 7px #000000);
        border-radius: $border-radius-vsm;
    }
    .person__card-personInfo {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 70%;
        margin-inline: auto;
        margin-top: -50px;
        min-height: 5.1875em;
        padding-inline: $padding-ml;
        background: rgba(0, 0, 0, 0.49);
        border: 1px solid $translucent-white;
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
        border-radius: 5px;
    }
}
