.brands-container {
    margin: 0 auto;
    margin-bottom: 100px;
    width: 100%;
    max-width: 1000px;
    padding: 0 1rem;
}

.title {
    text-align: center;
    color: rgb(3, 56, 99);
    //font-size: x-large;
    margin-inline: auto;
    font-size: clamp(3.5rem, -1.4554rem + 21.4286vw, 4rem);
    font-weight: 900;
    letter-spacing: 2px;
    color: #e5e4e2;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
        1px 1px 0 #000;
    -webkit-text-stroke: 2px #000000;
}

.client-brands {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.client-brands div {
    width: 25%;
    margin: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.client-brands div img {
    // display: grid;
    // grid-template-columns: 2fr;
    width: 100%;
    height: auto;
    object-fit: cover;
    filter: grayscale(1);
}

.client-brands div:hover img {
    filter: grayscale(0);
    box-shadow: 0 5px 35px 0px rgba(255, 215, 0, 1);
}

@media screen and (min-width: 2000px) {
    .client-brands div {
        width: 210px;
        margin: 2rem;
    }
}

@media screen and (max-width: 450px) {
    .client-brands div {
        width: 120px;
        margin: 1rem;
    }
}

@media screen and (min-width: 800px) {
    .brands-container {
        justify-content: center;
    }
    .title {
        text-align: center;
        h2 {
            text-align: center;
        }
    }

    .client-brands div {
        width: 28%;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
    }
}

@media screen and (max-width: 450px) {
    .client-brands div {
        width: 60%;
        margin: 1rem;
    }
    h2 {
        text-align: center;
    }
}
