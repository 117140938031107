@import "../../globalStyles/_variables";

.choice__container {
    padding-bottom: $padding-sm;
    background-color: $bg-clr-blue-1;
    color: $clr-white;
    border-radius: $border-radius-sm;
    @include minMediaQuery(large) {
        &-wrapper {
            display: flex;
            max-width: 1580px;
            margin: auto;
        }
    }
}
.choice__container-imagewrapper {
    padding: $padding-m;
    max-width: $maxWidth-2;
    margin: auto;

    @include minMediaQuery(large) {
        max-width: max-content;
    }
    &-img {
        border: 0.8px solid $clr-white;
    }
}
.choice__container-image {
    position: relative;
    z-index: 2;
}
.choice__container-image::before {
    position: absolute;
    content: "";
    right: -12px;
    top: 10px;
    border: 3px solid $clr-white;
    z-index: -1;
    width: 100%;
    height: 100%;
}

.choice__container-info {
    display: grid;
    gap: $padding-sm;
    padding-block: $padding-xsm;
    text-align: center;

    @include minMediaQuery(large) {
        border-left: 1px solid $translucent-white;
        margin-left: -70px;
    }

    @include minMediaQuery(xlarge) {
        margin-left: -140px;
        padding-inline: 5em 0;
    }

    &-title {
        padding-inline: $padding-xsm;
        padding-block: $padding-m;
    }
    &-title h2 {
        font-size: $fs-subheading-medium;
        padding-bottom: $padding-xsm;

        .text__outline {
            color: transparent;
            -webkit-text-stroke: 1px $clr-white;
        }
    }

    &-title p {
        font-size: $fs-subinfo;
        padding-inline: $padding-sm;
        max-width: $maxWidth-1;
        margin: auto;
    }

    &-button {
        button {
            @include buttonStyler($bg-clr-blue-6, $clr-white);
        }
    }
}
.choice__container-cards {
    display: grid;
    gap: $padding-xsm;

    .choice__container-card-info {
        padding-block: $padding-xsm;
    }
}
.choice__container-card-icon {
    font-size: 4.5rem;

    p {
        margin-block: -10px;
        line-height: 0.2;
        color: $clr-gold;
    }
}
