li.accordion__item {
    list-style: none;
}

.accordion {
    display: flex;
    width: 80%;
    height: 80%;
    margin: 0;
    padding: 0;
}

.accordion__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    transition: transform 0.3s ease;
}

.accordion__title {
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
    white-space: nowrap;
    position: absolute;
    bottom: 0.5em;
    right: 0.5em;
    writing-mode: vertical-rl;
    color: #ffffff;
    font-size: clamp(1em, 3vw - 0.5em, 3em);
    letter-spacing: 0.2em;
    text-transform: uppercase;
}

.accordion__title::after {
    content: "+";
    margin-top: 0.3em;
}

.accordion__description {
    position: absolute;
    width: 60%;
    left: 1em;
    bottom: 1em;
    color: #ffffff;
    transform: translateY(200px);
    opacity: 0;
    transition: all 0.3s ease-out;
    transition-property: opacity, transform;
}

.accordion__overlay {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(180deg, rgba(15, 15, 15, 0) 0%, #111111 100%);
    opacity: 1;
    transition: opacity 0.4s ease-out;
}

.accordion__item {
    position: relative;
    flex-grow: 1;
    width: 100px;
    height: 100%;
    background: hsl(0 0% 8%);
    transition: flex-grow 300ms ease;
    overflow: hidden;
}

.accordion__item:hover {
    flex-grow: 2;
}

.accordion__item:hover .accordion__overlay {
    opacity: 0.5;
}

.accordion__item:hover .accordion__title::after {
    content: none;
}

.accordion__item:hover .accordion__description {
    transform: translateY(0);
    opacity: 1;
}

.accordion__item:hover .accordion__image {
    transform: scale(1.7);
}

/* Media Queries */
@media only screen and (max-width: 480px) {

}