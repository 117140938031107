@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600&display=swap");
/* @import "../../../globalStyles/__variables.scss"; */

:root {
    --primary-color: rgba(0, 0, 255, 0.75);
    --secondary-color: rgba(255, 215, 0, 1);
    --tertiary-color: rgba(0, 0, 0, 0.9)
}

.text-align-center {
    text-align: center !important;
}

.outline {
    /* font-size: 5rem !important; */
    font-size: clamp(3.5rem, -1.4554rem + 21.4286vw, 4rem);
    font-weight: 900;
    letter-spacing: 2px;
    color: #e5e4e2;
    text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
        1px 1px 0 #000;
    margin-top: 1.65rem;
}

.no-outline {
    color: rgb(3, 56, 99);
    -webkit-text-stroke: 2px #000000;
    text-stroke: 2px #000000;
}

.blue-shade {
    color: rgb(3, 56, 99);
}

.gold-shade {
    color: rgba(255, 215, 0, 1);
}

.mt-70 {
    position: absolute;
    left: 0;
    right: 0;
    margin-inline: auto;
    /* margin-top: 70px; */
    height: fit-content;
    align-self: center;
}

.mt-100 {
    margin-top: 100px;
}

.mt-150 {
    margin-top: 150px;
}

.mt-200 {
    margin-top: 200px;
}

.mt-250 {
    margin-top: 250px;
}

.mt-300 {
    margin-top: 300px;
}

.text-gold {
    color: var(--secondary-color);
}

.mobile-padding {
    display: none;
}

/* leads to overflow on very samll devices */
/* .p-100 {
    padding: 100px;
} */

@media screen and (min-width: 700px) {
    .centered-header {
        margin: 0 auto;
        width: 50%;
        z-index: 15000;
    }
}

.scroll-rotator {
    display: none !important;
    height: fit-content;
    align-self: flex-end;
    margin-inline: auto;
    margin-bottom: -42px;
    text-align: center;
}

/* Real outline for modern browsers */
@supports ((text-stroke: 2px #e5e4e2) or (-webkit-text-stroke: 2px #e5e4e2)) {
    .outline {
        color: transparent;
        -webkit-text-stroke: 2px #e5e4e2;
        text-stroke: 2px #e5e4e2;
        text-shadow: none;
    }
}

/* Media Queries */
@media only screen and (max-width: 480px) {
    .outline {
        margin-top: -200px;
        font-size: clamp(2.5rem, -1.4554rem + 21.4286vw, 2.5rem);
    }

    .mobile-padding {
        display: block;
        padding-top: 15px !important;
        padding-bottom: 15px !important;
    }

    .about-con {
        margin-top: -200px;
    }
}
