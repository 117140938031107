@import "../../globalStyles/_variables";
.navbar__container {
    z-index: 4;
    position: fixed;
    top: 0;
    width: 100%;

    @include minMediaQuery(large) {
        // position: fixed;
        // top: 0;
        // width: 100%;
        background: rgba(22, 22, 22, 0.6);
        -webkit-backdrop-filter: blur(5px);
        backdrop-filter: blur(5px);
        display: flex;
        align-items: center;
    }
}

.navbar__logo-wrapper {
    display: flex;
    justify-content: space-between;
    padding: $padding-m;
    .navbar__toggle {
        position: fixed;
        right: $padding-xsm;
        height: fit-content;
        font-size: 2rem;
        z-index: 10;
        color: $clr-blue-3;

        p {
            line-height: 0;
            box-shadow: 1px 1px 8px 4px black;
        }

        p span {
            color: red;
        }
    }

    .navbar__logo {
        color: $clr-blue-3;
        border: 1px solid grey;
        padding: 0.3em;
    }
}

.navbar__links-wrapper {
    position: fixed;
    top: 0;
    height: 0px;
    width: 100%;
    overflow: hidden;
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    z-index: -1;
    transition: all 0.5s ease;
    // text-align: center;

    ul {
        padding-block: $padding-l;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    ul li a {
        color: $clr-white;
        font-weight: bold;
        display: inline-block;
        padding: $padding-sm;
    }

    @include minMediaQuery(large) {
        position: static;
        .nav__links ul {
            flex-direction: row;
        }
        ul {
            padding-block: 0;
        }
    }
}
.show_navigation {
    height: 100vh;
}
@include minMediaQuery(large) {
    .navbar__toggle {
        display: none;
    }
    .navbar__links-wrapper {
        height: auto !important;
    }
}
